import Modal from 'react-bootstrap/Modal'
import { Button, Container , Row, Col, Form} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import configData from './../../config/constant.json'

const ShareDialog = (props) => {
    let [error, setError] = useState(false)
    let [pending, setPending] = useState(false);
    let [showForm, setShowForm] = useState(true);
    let [shared, setShared] = useState(false)
    let [email, setEmail] = useState();
    let [shareButton, setShareButton] = useState(true);
    let [isShared, setIsShared] = useState(false);
    let [sharedWith, setSharedWith] = useState([]);
    let [sharedWithError, setSharedWithError] = useState(false)
    let [permission, setPermission] = useState("1");

    useEffect(() => {
        let data = {
            share_id: props.assetDetials._id,
            shareType: props.assetType
        }

        const abortCont = new AbortController();
        fetch(`${configData.SERVER_URL}/share/get_shared_with`, {
            credentials: configData.COOKIES_CONFIG,
            signal: abortCont.signal,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
            .then(res => {
                if (!res.ok) {
                    throw Error('Something Went Wrong')
                }
                return res.json()
            })
            .then(res_data => {
                if (res_data.status == false) {
                    throw Error(res_data.message)
                } else {
                    if (res_data.data.length > 0) {
                        setIsShared(true);
                        setSharedWith(res_data.data)
                    }
                }
            })
            .catch(err => {
                if (abortCont === "AbortError") {
                } else {
                    setSharedWithError('Error:( While Retreiving Shared With')
                }

            })


        return () => abortCont.abort();



    }, [])

    const shareWithOther = () => {
        setError(false);
        setPending(false);
        setShowForm(true);
        setShared(false);
        setEmail('');
        setShareButton(true)
    }

    // When handle Share
    let handleShare = () => {
        //Do more advance email validation here
        setShareButton(false)
        if (email == '') {
            return alert('Enter a valid email')
        }

        const abortCont = new AbortController();
        let data = {
            shareToEmail: email,
            permissionLevel: permission,
            sharedId: props.assetDetials._id,
            shareType: props.assetType
        }


        setShowForm(false)
        setPending(true)
        fetch(`${configData.SERVER_URL}/share/share_access`, {
            credentials: configData.COOKIES_CONFIG,
            signal: abortCont.signal,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
            .then(res => {
                if (!res.ok) {
                    throw Error('Something Went Wrong')
                }
                return res.json()
            })
            .then(res_data => {
                if (res_data.status == false) {
                    throw Error(res_data.message)
                } else {
                    setPending(false);
                    setShared(true);
                    setSharedWith(sharedWith => [...sharedWith, res_data.data])
                }
            })
            .catch(err => {
                if (abortCont === "AbortError") {
                } else {
                    setError(err.message);
                    setPending(false);
                }

            })


        return () => abortCont.abort();
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.assetDetials.folderName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    showForm &&
                    <div>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Container>
                                    <Row>
                                        <Col>
                                            <Form.Label>Share With</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" onChange={(e) => { setEmail(e.target.value) }} />
                                        </Col>
                                        <Col>
                                            <Form.Label>Permission Level</Form.Label>
                                            <Form.Select onChange={(e) => setPermission(e.target.value)}>
                                                <option value="1">Editor</option>
                                                <option value="2">Reader</option>
                                            </Form.Select>
                                        </Col>


                                    </Row>
                                </Container>
                                <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                </Form.Text>

                            </Form.Group>
                        </Form>
                        <hr />
                        {
                            isShared &&
                            <div>
                                <h6>Shared With:</h6>
                                {
                                    sharedWith.map((user) => (
                                        <p key={user.sharedTo._id}>={'>'}{user.sharedTo.email} || Permission: {user.permissionLevel==1 ? "Editor" : "Reader"}</p>
                                    ))
                                }
                            </div>
                        }
                        {
                            setSharedWithError && <p>{sharedWithError}</p>
                        }

                    </div>


                }
                {
                    pending && <p>Pending....</p>
                }
                {
                    error &&
                    <div>
                        <p>{error}</p>
                        <Button onClick={shareWithOther}>Try Again</Button>
                    </div>

                }
                {
                    shared &&
                    <>
                        <p>Asset Shared, to share with others</p>
                        <Button onClick={shareWithOther}>Click here</Button>
                    </>
                }

            </Modal.Body>
            <Modal.Footer>
                {shareButton && <Button onClick={handleShare}>Share</Button>}
            </Modal.Footer>
        </Modal>
    );
}

export default ShareDialog;