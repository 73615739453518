import { saveAs } from 'file-saver';
import configData from './../../config/constant.json'

export async function downloadFile(url) {
  try {
    const response = await fetch(url,{credentials: configData.COOKIES_CONFIG});

    if (!response.ok) {
      throw new Error(response);
    }

    const filename = response.headers.get('content-disposition')
      .split(';')
      .find(n => n.includes('filename='))
      .replace('filename=','')
      .replace('"','')
      .replace('"','')
      .trim()
    ;

    const blob = await response.blob();

    // Download the file
    saveAs(blob, filename);

  } catch (error) {
    throw new Error(error);
  }
}