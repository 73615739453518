import React from 'react'
import { Navbar, Nav, Offcanvas } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { logoutUser } from '../../utils/auth_service'
import { useHistory } from 'react-router-dom'

const NavbarComponent = () => {
    const history = useHistory();
    const handleLogout = () => {
        logoutUser();
        history.go('/auth/login')
    }
    const sendHome = () =>{
        history.push('/pages/home');
    }

    const sendShared = () => {
        history.push('/pages/shared');
    }
  
        return (
            <Navbar bg="light" expand={false}>
                <Navbar.Toggle aria-controls="offcanvasNavbar" />
                <Navbar.Brand as={Link} to="/">
                    Edu Drive
                </Navbar.Brand>
                <Nav.Link onClick={handleLogout}>
                    Log out
                </Nav.Link>
                <Navbar.Offcanvas style={{width:"20%"}}
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                    placement="start"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <Nav.Link onClick={sendHome}>Home</Nav.Link>
                            <Nav.Link onClick={sendShared}>Shared With Me</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
    
    
                </Navbar.Offcanvas>
            </Navbar>
        );
}

export default NavbarComponent;