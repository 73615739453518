
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useState, useEffect, useCallback } from "react";
import ShareDialog from "./ShareDailog";
import RenameDialog from "./RenameDialog"
import configData from './../../config/constant.json'
import  '../../index.css';

const FolderList = (props) => {
    let folders = props.folders;
    let changeFolder = props.changeFolder
    let handleFolderDelete = props.handleFolderDelete
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [shareDialog, setShareDialog] = useState(false);
    const [show, setShow] = useState(false);
    const [folderData, setFolder] = useState()
    const [renameDialog, setRenameDialog] = useState(false);
    

    const callMenu = useCallback((e, folder) => {
        e.preventDefault()
        setFolder(folder)
        setAnchorPoint({ x: e.pageX, y: e.pageY });
        setShow(true);
    },
        [setAnchorPoint, setShow]
    )
    const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);
    const showDialog = () => {
        setShareDialog(true);
    }
    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
          document.removeEventListener("click", handleClick);
        };
    }, [anchorPoint]);

    // Update the folder List
    function updateList(id, newName){
        folders.forEach(folder => {
            if(folder._id == id) {
                folder.folderName = newName
            }
        })
    }

    // Delete the file
    function deleteFolder(){
        
        fetch(`${configData.SERVER_URL}/fileBrowser/deletefolder?id=${folderData._id}`, {credentials: configData.COOKIES_CONFIG})
            .then(response => {
                if(!response.ok){throw Error('Something Went Wrong')}
                return response.json()
            })
            .then(res_data => {
                if (res_data.status == false) {
                    throw Error('')
                }

                // Remove it from the array
                folders = folders.filter( folder => {
                    if(folder._id != folderData._id) return folder
                })
               
                // Update the array
                handleFolderDelete(folders)
                             
            }).catch(err => {
                console.log(err)
                alert('Error While Deleting Folder')
            })
    }

    return (
        <div>
            {
                folders.map(folder => (
                    <
                        Button
                        variant="primary"
                        style={{ margin: '.1rem' }}
                        key={folder._id}
                        onClick={() => changeFolder(folder._id, folder.folderName)}
                        onContextMenu={(e) => callMenu(e, folder)}
                    >
                        <FontAwesomeIcon icon={faFolder} className="mr-2" />
                        {folder.folderName}
                    </Button>
                ))
            }
             {show ?
                       ( <ul
                        className="menu"
                        style={{
                          top: anchorPoint.y,
                          left: anchorPoint.x
                        }}
                      >
                        <li onClick={showDialog}>Share to..</li>
                        <li onClick={deleteFolder}>Delete</li>
                        <li onClick={() => setRenameDialog(true)}>Rename</li>
                        <hr className="divider" />
                        <li>Properties</li>
                        <li>Exit</li>
                      </ul>
                    ) : (
                      <> </>
                    )             
            }
            {shareDialog && <ShareDialog show={shareDialog}  onHide={() => setShareDialog(false)} assetDetials={folderData} assetType="folder"></ShareDialog>}
            {renameDialog && <RenameDialog show={renameDialog} onHide={() => setRenameDialog(false)} assetDetials={folderData} assetType="folder" updateList={updateList}></RenameDialog>}
        </div>

    );
}

export default FolderList;