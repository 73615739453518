import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import NavbarComponent from "./components/Navbar";
import Home from './Home'
import Shared from "./Shared";
import Profile from "./Profile";
import { isAuthenticated } from "../utils/auth_service";
import NotFound from "./NotFound";
import SharedAccess from "./SharedAccess";

const Pages = () => {
    if (isAuthenticated()) {
        return (
            <Router>
                <NavbarComponent />
                <br />
                <Route
                    path="/pages"
                    render={({ match: { url } }) =>
                    (
                        <Switch>
                            <Route path={`${url}/`} component={Home} exact />
                            <Route path={`${url}/home`} component={Home} exact />
                            <Route path={`${url}/shared`} component={Shared} />
                            <Route path={`${url}/profile`} component={Profile} />
                            <Route path={`${url}/shared_access`} component={SharedAccess} />
                            <Route path={`${url}/*`} component={NotFound} />
                        </Switch>
                    )
                    }
                />
            </Router>
        );

    } else {
        return <Redirect to='/auth/login' />
    }
}

export default Pages;