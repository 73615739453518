import { useState } from "react";
import { Link,useHistory } from "react-router-dom";
import configData from './../config/constant.json';
const Register = () => {
    let history = useHistory();
    let [firstName, setFirstName] = useState('');
    let [lastName, setLastName] = useState('');
    let [email, setEmail] = useState('');
    let [password, setPassword] = useState('');

    let registerUser = (e) => {
        e.preventDefault();
        let user = { firstName, lastName, email, password };

        fetch(`${configData.SERVER_URL}/auth/signup`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        })
            .then(response => response.json())
            .then(data => {
                if (data.status != true) {
                    alert(data.message)
                } else {
                    if (window.confirm('User Created, click on Ok to Log-in')) {
                        history.push('/auth/login')
                    }
                }

            });
    }

    return (
        <form onSubmit={registerUser}>
            <h3>Sign Up</h3>
            <div className="form-group">
                <label>First name  <span style={{color: "red"}}>*</span></label>
                <input
                    type="text" 
                    className="form-control"
                    placeholder="First name"
                    value={firstName}
                    onChange={(e) => { setFirstName(e.target.value) }}
                    required
                />
            </div>
            <div className="form-group">
                <label>Last name  <span style={{color: "red"}}>*</span></label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Last name"
                    value={lastName}
                    onChange={(e) => { setLastName(e.target.value) }}
                    required
                />
            </div>
            <div className="form-group">
                <label>Email address  <span style={{color: "red"}}>*</span></label>
                <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value) }}
                    required
                />
            </div>
            <div className="form-group">
                <label>Password  <span style={{color: "red"}}>*</span></label>
                <input
                    type="password"
                    className="form-control"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                    required
                />
            </div>
            <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
            <p className="forgot-password text-right">
                Already registered <Link to="/auth/login">sign in?</Link>
            </p>

        </form>
    );
}

export default Register;