import { useEffect, useState } from 'react'
import configData from './../../config/constant.json'

let useFolder = (currentFolder, route) => {
    let [folders, setFolders] = useState([]);
    let [files, setFiles] = useState([]);
    let [error, setError] = useState(false);
    let [isPending, setIsPending] = useState(true);

    useEffect(() => {
        const abortCont = new AbortController();

        fetch(`${configData.SERVER_URL}${route}?path=${currentFolder}`, {
            credentials: configData.COOKIES_CONFIG,
            signal: abortCont.signal,
        })
            .then(res => {
                if (!res.ok) {
                    throw Error('Could not load data for that resource')
                }
                return res.json()
            })
            .then(res_data => {
                if (res_data.status == false) {
                    throw Error('Could not load data for that resource')
                } else {
                        setFolders(res_data.data.folders);
                        setFiles(res_data.data.files);
                        setIsPending(false);
                }
            })
            .catch(err => {
                if (abortCont === "AbortError") {
                } else {
                    setError(err.message);
                    setIsPending(false);
                    
                }

            })


        return () => abortCont.abort();
    }, [currentFolder])


    return {
        folders,
        files,
        error,
        isPending,
        setFolders,
        setFiles
    }
}

export default useFolder;