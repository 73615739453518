import {Modal, Button} from 'react-bootstrap'

const NoPreview = (props) => {
    let showPreview = props.showPreview;
    let closePreview = props.closePreview
    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showPreview}
                onHide={closePreview}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        No Preview Available
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>
                        :( No Preview Available for the file selected
                    </h6>
                </Modal.Body>
                
            </Modal>
        </>
    );
}

export default NoPreview;