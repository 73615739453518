import {Link} from 'react-router-dom'
const LandingPage = () => {
    return ( 
        <div>
            <Link to='/auth/register'>Register</Link>
            <br />
        <Link to='/pages/'>Pages</Link>

        </div>
        
     );
}
 
export default LandingPage;