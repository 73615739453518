import { Button } from "react-bootstrap";
const AskAccess = (props) => {
    const askOwner = props.handleClick
    return (
        <div>
            <p>You don not have access for the file</p>
            <Button onClick={askOwner}>Ask Owner, for permission</Button>
        </div>
    );
}

export default AskAccess
