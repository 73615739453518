import { useEffect, useState } from 'react'
import configData from './../../config/constant.json'

let useHaveAccess = (sharedId, sharedType) => {
    let [haveAccess, setAccess] = useState(false)
    let [error, setError] = useState(false);
    let [isPending, setIsPending] = useState(true);
    let [askAccess, setAskAccess] = useState(false)

    useEffect(() => {
        const abortCont = new AbortController();

        fetch(`${configData.SERVER_URL}/share/has_access`, {
            credentials: configData.COOKIES_CONFIG,
            signal: abortCont.signal,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({sharedId, sharedType}),
        })
            .then(res => {
                if (!res.ok) {
                    throw Error('Could not load data for that resource')
                }
                return res.json()
            })
            .then(res_data => {
                if (res_data.status == false) {
                   setAccess(false);
                   setIsPending(false)
                   setAskAccess(true)
                } else {
                        setIsPending(false);
                        setAccess(true)    
                }
            })
            .catch(err => {
                if (abortCont === "AbortError") {
                } else {
                    setError(err.message);
                    setIsPending(false);
                }

            })


        return () => abortCont.abort();
    }, [])


    return {
        haveAccess,
        error,
        isPending,
        askAccess,
        setIsPending,
        setError,
        setAskAccess
    }
}

export default useHaveAccess;