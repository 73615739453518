import { useLocation, Redirect , useHistory} from "react-router-dom";
import useHaveAccess from "./hooks/useHaveAccess"
import AskAccess from './components/AskAccess'
import { useState } from "react";
import { Button } from "react-bootstrap";
import configData from './../config/constant.json'

const SharedAccess = () => {
    // http://localhost:3000/pages/shared_access?id=6214b2ac8681ac833480b62c&type=folder
    let history = useHistory();
    // Getting the query params from the url
    const search = useLocation().search;
    const sharedId = new URLSearchParams(search).get('id');
    const sharedType = new URLSearchParams(search).get('type')

    // useState to redirect to the shared after asking for permisson
    let [hadAsk, setHadAsk] = useState(false)

    // useHaveAccess custom hook
    let { haveAccess, error, isPending, askAccess, setIsPending, setError, setAskAccess } = useHaveAccess(sharedId, sharedType)

    // AskOwner for permission
    const askOwner = () => {
        const abortCont = new AbortController();
        setIsPending(true);
        setAskAccess(false);
        fetch(`${configData.SERVER_URL}/share/ask_owner`, {
            credentials: configData.COOKIES_CONFIG,
            signal: abortCont.signal,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sharedId, sharedType }),
        })
            .then(res => {
                if (!res.ok) {
                    throw Error(':( Error, while giving Access')
                }
                return res.json()
            })
            .then(res_data => {
                if (res_data.status == false) {
                    setIsPending(false);
                    throw Error(':( Error, while giving Access')
                } else {
                    setIsPending(false);
                    setHadAsk(true);
                }
            })
            .catch(err => {
                if (abortCont === "AbortError") {
                } else {
                    setError(err.message);
                    setIsPending(false);
                }

            })


        return () => abortCont.abort();
    }

    const handleHome = () => {
        history.push('/pages/home')
    }

    return (
        <>
            {error && <p>:( {error}</p>}
            {isPending && <p>Loading...</p>}
            {haveAccess && <Redirect to='/pages/shared'></Redirect>}
            {askAccess && <AskAccess handleClick={askOwner}></AskAccess>}
            {hadAsk && 
                <div>
                    <p>Owner has been notified</p>
                    <p>Click home button to redirect to home</p>
                    <Button onClick={handleHome}>Home</Button>
                </div>
            }
        </>

    )
}

export default SharedAccess;