import { faFileUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const AddFileButton = (props) => {
    let uploadFile = props.uploadFile;
    return (  
        <label className="btn btn-outline-success btn-lg m-0 mr-2">
            <FontAwesomeIcon icon={faFileUpload} />
            <input
            onChange={uploadFile}
            type="file"
            style={{ opacity: 0, position: "absolute", left: "-9999px" }}
            />
      </label>
    );
}
 
export default AddFileButton;