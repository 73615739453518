import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import LandingPage from './pages/LandingPage';
import Auth from './auth_pages/Auth'
import Pages from './pages/Pages'


function App() {

  return (
    <Router>
      <Route path='/' exact component={LandingPage} />
      <Route path='/auth' component={Auth} />
      <Route path='/pages' component={Pages} />
    </Router>
  );
}

export default App;
