import FolderList from "./components/FolderList";
import FileList from "./components/FileList";
import PathList from "./components/PathList";

import { useState} from "react";
import useFolder from "./hooks/useFolder";

import configData from './../config/constant.json'
import { downloadFile } from "./hooks/downloadFile";

const Shared = () => {
    let [currentFolder, setCurrentFolder] = useState('root');
    let [pathArray, setPathArray] = useState([
        {
            path: 'root',
            folderName: 'Shared',
            index: 0
        }
    ])

    //UseFolder hook
    let { folders, files, error, isPending, setFolders, setFiles } = useFolder(currentFolder, "/share/get_shared");

    //When folder is changed
    function changeFolder(folderId, folderName) {
        setCurrentFolder(folderId)
        setPathArray(pathArray => [...pathArray, { path: folderId, folderName, index: pathArray.length }])
    }

    // To Download a file
    function handleFileDownload(id) {
        downloadFile(`${configData.SERVER_URL}/fileBrowser/downloadFile?file=${id}`)
    }

    // When user change a path
    function handleRoute(id, index) {
    
        if (id == currentFolder) {
           
            return;
        }

        pathArray = pathArray.slice(0, index + 1)
        setCurrentFolder(id)
        setPathArray(pathArray)
    }

    //When folder is changed
    function changeFolder(folderId, folderName) {
        setCurrentFolder(folderId)
        setPathArray(pathArray => [...pathArray, { path: folderId, folderName, index: pathArray.length }])
    }

    return (
        <div>
            {isPending && <p>Loading data...</p>}
            {error && <p>{error}</p>}
            <hr />
            <PathList pathArray={pathArray} handleRoute={handleRoute} />
            <br />
            <h4>Folders</h4>
            <hr />
            {folders.length > 0 && <FolderList folders={folders} changeFolder={changeFolder}></FolderList>}
            {folders.length === 0 && <p>:( Nothing here</p>}
            <hr />
            <h4>Uploaded Files</h4>
            <hr />
            {files.length > 0 && <FileList files={files} handleFileDownload={handleFileDownload}></FileList>}
            {files.length === 0 && <p>:( Nothing here</p>}
        </div> 
    );
}

export default Shared;