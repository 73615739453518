import Cookies from 'js-cookie'
import configData from './../config/constant.json'

let isAuthenticated = () => {
    let jwt = Cookies.get('jwt')
    // Later call a route from here to the server 
    // To check if jwt is proper
    if(jwt!==undefined){
        
        return true
    }

    
    return false;
}

let logoutUser = () => {
    Cookies.remove('jwt',{ path: '/', domain: configData.DOMAIN  })
}   


export {isAuthenticated, logoutUser};