import { Breadcrumb } from "react-bootstrap"

const PathList = (props) => {
    let pathArray = props.pathArray
    let handleRoute = props.handleRoute
    return (
        <div>
            <Breadcrumb
                className="flex-grow-1"
                listProps={{ className: "bg-white pl-0 m-0" }}
            ></Breadcrumb>
            <div>
                {pathArray.map(folder => (
                    <Breadcrumb.Item
                        key={folder.path}
                        className="text-truncate d-inline-block"
                        style={{ maxWidth: "150px" }}
                        onClick={() => {handleRoute(folder.path, folder.index)}}
                    >
                        {folder.folderName}
                    </Breadcrumb.Item>
                ))}
            </div>
        </div>

    );
}

export default PathList;