import { Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderPlus } from "@fortawesome/free-solid-svg-icons"
import { useState } from 'react'


const AddFolder = (props) => {
    let handleAddFolder = props.handleAddFolder

    const [modalState, setModal] = useState(false);
    const [folderName, setName] = useState('');

    function openModal() {
        setModal(true)
    }

    function closeModal() {
        setModal(false)
    }

    function handleSubmit(e) {
        e.preventDefault();
        handleAddFolder(folderName)
        setName('');
        closeModal();
    }

    
    return (
        <>
            <Button onClick={openModal} variant="outline-success" size="lg" style={{ marginRight: '10px'}}>
                <FontAwesomeIcon icon={faFolderPlus} />
            </Button>
            <Modal show={modalState} onHide={closeModal}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>
                                Folder Name
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={folderName}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>Close</Button>
                        <Button variant="success" type="submit">Add Folder</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>

    );
}

export default AddFolder;