import { faFile } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from 'react-bootstrap'
import { useState, useEffect, useCallback } from "react";
import '../../index.css'
import ShareDialog from "./ShareDailog";
import RenameDialog from "./RenameDialog"
import configData from './../../config/constant.json'

const FileList = (props) => {
    let files = props.files
    let handleFileDownload = props.handleFileDownload
    let handleViewFile = props.handleViewFile
    let handleFileDelete = props.handleFileDelete

    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [show, setShow] = useState(false);
    const [file_id, setFile_id] = useState('')
    const [file_detials, setFileDetials] = useState('')
    const [shareDialog, setShareDialog] = useState(false);
    const [renameDialog, setRenameDialog] = useState(false);
    
    const callMenu = useCallback((e, file) => {
        setFile_id(file._id)
        setFileDetials(file)
        e.preventDefault()
        setAnchorPoint({ x: e.pageX, y: e.pageY });
        setShow(true);
    },
        [setAnchorPoint, setShow]
    )
    const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [anchorPoint]);

    // Update the filename
    function updateList(id, newName){
        files.forEach(file => {
            if(file._id == id) {
                file.originalName = newName
            }
        })
    }

    // Delete the file
    function deleteFile(){
        
        fetch(`${configData.SERVER_URL}/fileBrowser/deletefile?id=${file_detials._id}`, {credentials: configData.COOKIES_CONFIG})
            .then(response => {
                if(!response.ok){throw Error('Something Went Wrong')}
                return response.json()
            })
            .then(res_data => {
                if (res_data.status == false) {
                    throw Error('')
                }
                // Remove it from the array
                files = files.filter( file => {
                    if(file._id != file_detials._id) return file
                })
               
                // Update the array
                handleFileDelete(files)
                             
            }).catch(err => {
                alert('Error While Deleting File')
            })
    }
    
    return (
        <div>
            {
                files.map(file => (
                    <Button
                        key={file._id}
                        style={{ marginLeft: '.5rem' }}
                        variant="outline-dark"
                        onContextMenu={(e) => callMenu(e, file)}
                    >
                        <FontAwesomeIcon icon={faFile} className="mr-2" />
                        {file.originalName}
                    </Button>
                ))
            }
            {show ?
                (<ul
                    className="menu"
                    style={{
                        top: anchorPoint.y,
                        left: anchorPoint.x
                    }}
                >
                    <li onClick={() => handleViewFile(file_id, file_detials.extension)}>View in new tab</li>
                    <li onClick={() => setRenameDialog(true)}>Rename</li>
                    <li onClick={()=>{setShareDialog(true)}}>Share to..</li>
                    <li onClick={deleteFile}>Delete</li>
                    <hr className="divider" />
                    <li onClick={() => handleFileDownload(file_id)}>Download</li>
                    <li>Properties</li>
                    <li>Exit</li>
                </ul>
                ) : (
                    <> </>
                )
            }
            {shareDialog && <ShareDialog show={shareDialog}  onHide={() => setShareDialog(false)} assetDetials={file_detials} assetType="file"></ShareDialog>}
            {renameDialog && <RenameDialog show={renameDialog} onHide={() => setRenameDialog(false)} assetDetials={file_detials} assetType="file" updateList={updateList}></RenameDialog>}
        </div>

    );
}

export default FileList;