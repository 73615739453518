import { Container } from 'react-bootstrap'
import AddFolder from "./components/AddFolder";
import { useState } from "react";
import useFolder from "./hooks/useFolder";
import FolderList from "./components/FolderList";
import AddFileButton from "./components/AddFile";
import FileList from "./components/FileList";
import { downloadFile } from "./hooks/downloadFile";
import PathList from "./components/PathList";
import configData from './../config/constant.json';
import Spinner from 'react-bootstrap/Spinner';
import NoPreview from './components/NoPreview';

const Home = () => {
    let [uploadFileFlag, setuploadFileFlag] = useState(false);
    let [currentFolder, setCurrentFolder] = useState('root');
    let [showPreview, setShowPreview] = useState(false)
    let [pathArray, setPathArray] = useState([
        {
            path: 'root',
            folderName: 'root',
            index: 0
        }
    ])
    

    //When folder is changed
    function changeFolder(folderId, folderName) {
        setCurrentFolder(folderId)
        setPathArray(pathArray => [...pathArray, { path: folderId, folderName, index: pathArray.length }])
    }

    //UseFolder hook
    let { folders, files, error, isPending, setFolders, setFiles } = useFolder(currentFolder, "/fileBrowser/getUploads");

    // Creates a folder within the current folder
    function handleAddFolder(folderName) {
        //Create a folder in the database
        fetch(`${configData.SERVER_URL}/fileBrowser/createFolder?path=${currentFolder}`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ folderName }),
            credentials: configData.COOKIES_CONFIG
        })
            .then(response => response.json())
            .then(res_data => {
                if (res_data.status == false) {
                    throw Error('')
                }
                setFolders(folders => [...folders, res_data.data])
            }).catch(err => alert('Error While Creating Folder'))
    }

    // Upload File
    function uploadFile(e) {
        setuploadFileFlag(true)
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        //Call the API for uploading the file
        fetch(`${configData.SERVER_URL}/fileBrowser/uploadFile?path=${currentFolder}`, {
            method: 'post',
            body: formData,
            credentials: configData.COOKIES_CONFIG
        })
            .then(res => res.json())
            .then(res_data => {
                if (res_data.status === false) {
                    throw Error('Error While File Uploading');
                }
                setFiles(files => [...files, res_data.data])
                setuploadFileFlag(false)
            }).catch(err => {
                setuploadFileFlag(false)
                alert(err.message);
            })

    }

    // To Download a file
    function handleFileDownload(id) {
        downloadFile(`${configData.SERVER_URL}/fileBrowser/downloadFile?file=${id}`)
    }

    // When user change a path
    function handleRoute(id, index) {
        if (id == currentFolder) {
            return;
        }

        pathArray = pathArray.slice(0, index + 1)
        setCurrentFolder(id)
        setPathArray(pathArray)
    }

    // View File
    async function handleViewFile(id, extention) {
        extention = extention.toLowerCase()
        const supportedPreview = ['jpeg', 'png', 'jpg', 'gif', 'mp4', 'pdf', 'txt']

        if (supportedPreview.includes(extention)) {
            const response = await fetch(`${configData.SERVER_URL}/fileBrowser/downloadFile?file=${id}`, { credentials: configData.COOKIES_CONFIG });
            const blob = await response.blob();
            const url = await URL.createObjectURL(blob);
            window.open(url);
        }
        else {
            setShowPreview(true)
            
        }
    }

    // Close No-Preview Modal
    function closePreview() {
        setShowPreview(false)
    }

    // Update the files after delete
    function handleFileDelete(files){
        setFiles(files)
    }

    // Update the folders after delete
    function handleFolderDelete(folders){
        setFolders(folders)
    }

    return (
        <>
            <Container fluid>
                <AddFolder handleAddFolder={handleAddFolder} ></AddFolder>
                <AddFileButton uploadFile={uploadFile}></AddFileButton>
            </Container>
            <br />
            {isPending && <p>Loading data...</p>}
            {error && <p>{error}</p>}
            <hr />
            <PathList pathArray={pathArray} handleRoute={handleRoute} />
            <br />
            <h4>Folders</h4>
            <hr />
            {folders.length > 0 && <FolderList folders={folders} changeFolder={changeFolder} handleFolderDelete={handleFolderDelete}></FolderList>}
            {folders.length === 0 && <p> :( Nothing here</p>}
            <hr />
            <h4>Uploaded Files</h4>
            <hr />
            {uploadFileFlag && <Spinner animation="border" variant="primary" />}
            {files.length > 0 && <FileList files={files} handleFileDownload={handleFileDownload} handleViewFile={handleViewFile} handleFileDelete={handleFileDelete}></FileList>}
            {files.length === 0 && <p> :( Nothing here</p>}
            {showPreview && <NoPreview showPreview={showPreview} closePreview={closePreview}></NoPreview>}
            

        </>
    );
}

export default Home;